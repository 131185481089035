export enum ColumnDefEnum {
  CREATED_ON = 'createdOn',
  LAST_CHANGE_DATE = 'lastChangeDate',
  CUSTOMER = 'customer',
  SPECIALTY_FIELD = 'specialtyField',
  ASSIGNED_TO = 'assignedTo',
  STATUS = 'status',
  PRIORITY = 'priority',
  ADDRESS = 'address',
  REASON = 'reason',
  CREATED_BY = 'createdBy',
  CUSTOMER_TRANSACTION_NUMBER = 'customerTransactionNumber',
  MENU = 'menu',
}

export enum ColumnFilterEnum {
  CREATED_ON = 'date.createdOn',
  LAST_CHANGE_DATE = 'date.lastChangeDate',
  CUSTOMER = 'customer',
  SPECIALTY_FIELD = 'specialtyField',
  ASSIGNED_TO = 'user.assignedTo',
  STATUS = 'status',
  ADDRESS = 'address',
  REASON = 'reason',
  CREATED_BY = 'user.createdBy',
  CUSTOMER_TRANSACTION_NUMBER = 'customerTransactionNumber',
}
