export class OrderFilter {
  customer: string[] = [];
  specialtyField: string[] = [];
  reason: string[] = [];
  status: string[] = [];
  createdBy: string[] = [];
  assignedTo: string[] = [];
  address: string[] = [];
  customerTransactionNumber: string[] = [];

  [key: string]: string[];
}
