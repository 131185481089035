import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderOverviewComponent } from './order-overview/order-overview.component';
import { UIModule } from '../ui.module';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { OrderRoutingModule } from './order-routing.module';
import { OrderTableComponent } from './order-overview/order-table/order-table.component';
import { LoginModule } from '../login/login.module';
import { UtilModule } from '../shared/util/util.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [OrderOverviewComponent, OrderTableComponent],
  imports: [
    CommonModule,
    UIModule,
    RouterLink,
    RouterLinkActive,
    TranslateModule,
    OrderRoutingModule,
    LoginModule,
    UtilModule,
    SharedModule,
  ],
})
export class OrderModule {}
